<template>
  <div
    class="onboarding-screen"
    :style="{
      backgroundImage: 'url(' + plateImage('png', 'background') + ')',
    }"
  >
    <div
      v-if="displayFirstScreen"
      class="first-last-screen"
    >
      <div class="text-text-default bold text-2xl text-center mb-4">
        {{ translate('welcomeToSuperThread') }}
      </div>
      <div class="mb-[60px] flex items-center flex-wrap justify-center">
        <span class="text-text-default text-center">
          {{ translate('wedLikeToTake') }}
        </span>
        <span class="text-text-default text-center bold ml-2">
          {{ translate('thirtySeconds') }}
        </span>
        <img
          class="inline-block ml-2 mr-2"
          :src="plateImage('svg', 'fast-clock')"
          alt="clock"
        />
        <span class="text-text-default text-center">
          {{ translate('toShowYouAround') }}
        </span>
      </div>
      <ButtonCustom
        :text="translate('getStarted')"
        :type="'popup-button-wrap attention'"
        class="first-last-screen-button"
        :button-dimensions="{
          width: 262,
          height: 48,
        }"
        @click="continueOnboarding"
      />
    </div>
    <div
      v-show="!displayLastScreen && !displayFirstScreen"
      class="onboarding-content"
    >
      <div
        v-for="(lection, index) in lections"
        :key="lection.id"
        class="onboarding-lectures"
      >
        <div
          :class="{ 'hidden-image': selectedLectureIndex !== index }"
          class="lecture-steps"
        >
          <div
            v-for="(step, stepIndex) in lection.steps"
            :key="stepIndex"
            class="step-wrap"
          >
            <div :class="{ 'hidden-image': selectedStepIndex !== stepIndex }">
              <div class="image-container">
                <picture
                  v-if="step.imgBase"
                  class="step-image"
                >
                  <source
                    :srcset="plateImage('avif', step.imgBase)"
                    type="image/avif"
                  />
                  <source
                    :srcset="plateImage('webp', step.imgBase)"
                    type="image/webp"
                  />
                  <img
                    class="step-image"
                    :src="plateImage('png', step.imgBase)"
                    alt="no pages"
                  />
                </picture>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ButtonCustom
        :class="{
          show: !hideContinueButton,
        }"
        :text="translate('continue')"
        :type="'popup-button-wrap attention'"
        :button-dimensions="{
          width: 131,
          height: 36,
        }"
        class="continue-button"
        @click="nextLecture"
      />
      <div
        class="onboarding-footer"
        :class="{
          show: !hideContinueButton,
        }"
      >
        <div class="skip-button m-0"></div>
        <div class="step-buttons">
          <div
            v-for="(lection, index) in lections"
            :key="lection.id"
            class="step-button"
            :class="selectedLectureIndex === index ? 'bg-attention-bg w-6' : ''"
          ></div>
        </div>
        <div
          class="skip-button pt"
          @click="goToLastScreen"
        >
          {{ translate('skipToEnd') }}
          <em
            class="custom-icon flex h-4 w-4 bg-icon-default"
            :style="getCustomIconStyle(iconPath('arrow-right'))"
          />
        </div>
      </div>
    </div>
    <div
      v-if="displayLastScreen"
      class="first-last-screen"
    >
      <div class="text-text-default bold text-2xl text-center mb-4">
        {{ translate('youreGoodToGo') }}
      </div>
      <div class="mb-[60px] text-center">
        <span class="text-text-default text-center">
          {{ translate('youCanAlways') }}
        </span>
      </div>
      <ButtonCustom
        :text="translate('openSuperthread')"
        :type="'popup-button-wrap attention'"
        class="first-last-screen-button"
        :button-dimensions="{
          width: 262,
          height: 48,
        }"
        @click="closeOnboarding"
      />
    </div>
    <div class="onboarding-logo-header">
      <img
        width="34"
        height="34"
        :src="logoImg('onboarding-logo')"
        alt="superthread logo"
      />
    </div>
  </div>
</template>

<script>
import ButtonCustom from '@/components/elements/ButtonCustom';
import customIconsMixin from '@/mixins/customIconsMixin';
import checkPlatform from '@/mixins/checkPlatform';
export default {
  name: 'Onboarding',
  components: {
    ButtonCustom,
  },
  mixins: [customIconsMixin, checkPlatform],
  data() {
    return {
      lastScreen: false,
      firstScreen: true,
      selectedLectureIndex: 0,
      selectedStepIndex: 0,
    };
  },
  computed: {
    hideContinueButton() {
      return this.lections[this.selectedLectureIndex]?.steps[this.selectedStepIndex]
        ?.hideContinueButton;
    },
    lections() {
      return [
        {
          id: 'lection-1',
          steps: [
            {
              imgBase: 'workspace',
              hideContinueButton: true,
            },
            {
              imgBase: 'workspace-workspace',
              hideContinueButton: true,
            },
          ],
        },
        {
          id: 'lection-3',
          steps: [
            {
              imgBase: 'favourites',
            },
          ],
        },
        {
          id: 'lection-4',
          steps: [
            {
              imgBase: 'spaces',
            },
          ],
        },
        {
          id: 'lection-5',
          steps: [
            {
              imgBase: 'spaces-private',
            },
          ],
        },
        {
          id: 'lection-6',
          steps: [
            {
              imgBase: 'spaces-contain',
            },
          ],
        },
        {
          id: 'lection-7',
          steps: [
            {
              imgBase: 'spaces-add-members',
            },
          ],
        },
        {
          id: 'lection-8',
          steps: [
            {
              imgBase: 'boards-organise',
            },
          ],
        },
        {
          id: 'lection-9',
          steps: [
            {
              imgBase: 'boards-move-cards',
            },
          ],
        },
        {
          id: 'lection-10',
          steps: [
            {
              imgBase: 'boards-reorder-columns',
            },
          ],
        },
        {
          id: 'lection-11',
          steps: [
            {
              imgBase: 'boards-switch-layout',
            },
          ],
        },
        {
          id: 'lection-12',
          steps: [
            {
              imgBase: 'board-switch-layout-timeline',
            },
          ],
        },
        {
          id: 'lection-13',
          steps: [
            {
              imgBase: 'cards-collaborate',
            },
          ],
        },
        {
          id: 'lection-14',
          steps: [
            {
              imgBase: 'cards-break-down',
            },
          ],
        },
        {
          id: 'lection-15',
          steps: [
            {
              imgBase: 'cards-subscribe',
            },
          ],
        },
        {
          id: 'lection-16',
          steps: [
            {
              imgBase: 'cards-relations',
            },
          ],
        },
        {
          id: 'lection-17',
          steps: [
            {
              imgBase: this.isApple ? 'search-mac' : 'search-windows',
            },
          ],
        },
      ];
    },
    displayLastScreen() {
      return this.lastScreen;
    },
    displayFirstScreen() {
      return this.firstScreen;
    },
  },
  methods: {
    plateImage(extension, type) {
      return `${this.$constants.staticImages}/onboarding-flow-images/${type}-${this.theme}-v1.${extension}`;
    },
    nextLecture() {
      if (this.selectedLectureIndex < this.lections.length - 1) {
        this.selectedLectureIndex++;
        this.selectedStepIndex = 0;
      } else {
        this.lastScreen = true;
      }
    },
    goToLastScreen() {
      this.lastScreen = true;
    },
    closeOnboarding() {
      this.$emit('closeOnboarding');
    },
    continueOnboarding() {
      this.firstScreen = false;
      const timeout = setTimeout(() => {
        this.selectedStepIndex++;
        this.lections[this.selectedLectureIndex].steps[this.selectedStepIndex].hideContinueButton =
          false;
        clearTimeout(timeout);
      }, 300);
    },
    logoImg(name) {
      const images = require.context('../../assets/images/', false);
      return images(`./${name}-light.svg`);
    },
  },
};
</script>

<style lang="scss">
.onboarding-screen {
  z-index: 99999;
  height: 100vh !important;
  width: 100vw !important;
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  @apply bg-background-strongest;
  .onboarding-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .step-wrap {
      padding: 0 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .step-image {
        width: 100%;
        max-width: 1440px;
        max-height: 900px;
        height: 100vh;
        object-fit: contain;
      }
    }
    .onboarding-footer {
      position: relative;
      flex-wrap: wrap;
      margin-top: 27px;
      max-width: 1086px;
      width: 100%;
      margin-bottom: 40px;
      opacity: 0;
      @apply flex justify-between items-center;
      .step-buttons {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
        .step-button {
          @apply bg-dividers-default w-2 h-1 rounded-lg;
        }
      }
      .finish-button {
        @apply text-text-placeholder pr-2 absolute right-0 flex items-center gap-2;
        .finish-icon {
          @apply w-4 h-4 flex;
        }
      }

      .skip-button {
        @apply text-text-default flex items-center gap-1 mx-2;
      }
    }
    .continue-button {
      margin-top: -80px;
      z-index: 2;
      opacity: 0;
    }

    .show {
      animation: fade-in-animation 1.1s forwards;
    }

    @keyframes fade-in-animation {
      from {
        opacity: 0; /* Start with opacity 0 */
      }
      to {
        opacity: 1; /* Transition to opacity 1 */
      }
    }
  }
  .onboarding-logo-header {
    position: absolute;
    top: 0;
    left: 0;
    margin: 32px;
  }
}
.image-container {
  position: relative;
}

.image-container picture {
  display: block;
}

.image-container span {
  position: absolute;
  top: 0;
  left: 0;
}

.first-last-screen {
  @apply flex flex-col items-center justify-center;
}

.hidden-image {
  opacity: 0;
  height: 0;
}

@media (max-width: 768px) {
  .footer-buttons {
    gap: 16px !important;
  }
  .step-buttons {
    gap: 4px !important;
  }
  .onboarding-footer {
    @apply flex-col;
    .skip-button {
      margin-top: 16px;
      margin-bottom: 32px;
    }
  }
}
</style>
