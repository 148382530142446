<template>
  <div
    v-tooltip="{
      content: translate('upgradePlanBadgeTooltip', { spaceOwner: owner?.display_name }),
      trigger: 'hover',
      classes: 'black-tooltip mt-19 child-card-button-tooltip',
      placement: 'bottom',
      boundariesElement: 'window',
    }"
    class="badge-label"
    :class="{ 'cursor-default': !isElevated }"
    @click="openPopup"
  >
    <em
      v-if="icon"
      class="custom-icons w-3 h-3 mr-1"
      :style="[getCustomIconStyle(iconPath(`${props.icon}`))]"
    />
    {{ translate(`${props.text}`) }}
  </div>
</template>

<script setup lang="ts">
import useTranslate from '@/utilities/composables/useTranslate';
import useCustomIcon from '@/utilities/composables/useCustomIcon';
import { computed, getCurrentInstance } from 'vue';
import useCurrentRole from '@/utilities/composables/useCurrentRole';

const _this = getCurrentInstance()?.proxy as any;

const { translate } = useTranslate();
const { iconPath, getCustomIconStyle } = useCustomIcon();
const { isElevated } = useCurrentRole();

const props = defineProps<{
  icon?: string;
  text: string;
}>();

const owner = computed(() => {
  return _this.$store.getters.getMemberById(_this.$store.getters.getWorkspaceOwner?.id);
});

const openPopup = (event: any) => {
  if (!isElevated.value) return;
  _this.$emit('click', event);
};
</script>

<style lang="scss">
.badge-label {
  @apply bg-attention-bg text-attention-text flex flex-row rounded font-normal justify-center items-center px-2 h-5 text-xs;

  em {
    background-color: var(--attentionText);
  }
}

.badge-label:hover {
  @apply cursor-pointer;
}
</style>
