var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
    content: _setup.translate('upgradePlanBadgeTooltip', { spaceOwner: _setup.owner?.display_name }),
    trigger: 'hover',
    classes: 'black-tooltip mt-19 child-card-button-tooltip',
    placement: 'bottom',
    boundariesElement: 'window',
  }),expression:"{\n    content: translate('upgradePlanBadgeTooltip', { spaceOwner: owner?.display_name }),\n    trigger: 'hover',\n    classes: 'black-tooltip mt-19 child-card-button-tooltip',\n    placement: 'bottom',\n    boundariesElement: 'window',\n  }"}],staticClass:"badge-label",class:{ 'cursor-default': !_setup.isElevated },on:{"click":_setup.openPopup}},[(_vm.icon)?_c('em',{staticClass:"custom-icons w-3 h-3 mr-1",style:([_setup.getCustomIconStyle(_setup.iconPath(`${_setup.props.icon}`))])}):_vm._e(),_vm._v(" "+_vm._s(_setup.translate(`${_setup.props.text}`))+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }