var render = function render(_c,_vm){return _c('div',{staticClass:"shortcut-key-pill drag-scroll-disable usn border",class:_vm.data.staticClass,style:({
    backgroundColor: _vm.props.backgroundColor,
    minWidth: `${_vm.props.size}px`,
    height: `${_vm.props.size}px`,
    fontSize: `${_vm.props.fontSize}px`,
    borderRadius: `${_vm.props.borderRadius}px`,
    color: _vm.props.fontColor,
    border: `1px solid ${_vm.props.borderColor}`,
  })},[_c('span',{staticClass:"drag-scroll-disable"},[_vm._v(_vm._s(_vm.props.text))])])
}
var staticRenderFns = []

export { render, staticRenderFns }