<template>
  <div
    ref="addMemberModal"
    class="modal add-member-modal"
    tabindex="0"
    @keydown.esc.stop="closePopup"
    @mousedown="closePopup"
  >
    <div
      class="popup-window add-member-popup"
      @mousedown.stop
    >
      <PopupHeader
        :title="translate('inviteToWorkspace', { workspaceName: currentWorspaceName })"
        :description="translate('inviteToWorkspaceDescription')"
        class="border-0 pl-6 pb-6"
        @close="closePopup"
      />
      <MembersInviteForm
        ref="membersInvite"
        @close="closePopup"
        @focusPopup="focusPopup"
      />
    </div>
  </div>
</template>

<script>
import PopupHeader from '@/components/elements/PopupHeader';
import MembersInviteForm from '@/components/widgets/MembersInviteForm';
import customIconsMixin from '@/mixins/customIconsMixin';

export default {
  name: 'AddMember',
  components: {
    PopupHeader,
    MembersInviteForm,
  },
  mixins: [customIconsMixin],
  computed: {
    upgradePlanPopUpIsDisplayed() {
      return !!this.$store.getters.getShowUpgradePlanPopup;
    },

    currentWorspaceName() {
      return this.$store.getters.getTeam.team_name ?? '';
    },
  },
  watch: {
    upgradePlanPopUpIsDisplayed(newVal) {
      if (!newVal) {
        this.focusPopup();
      }
    },
  },
  methods: {
    focusPopup() {
      this.$refs.addMemberModal.focus();
    },
    closePopup() {
      if (this.$refs.membersInvite.showProjectDropdown === true) {
        this.$refs.membersInvite.showProjectDropdown = false;
        return;
      }
      this.$store.dispatch('setAddMemberPopup', false);
      this.$store.dispatch('setMembersSearchInput', '');
      this.$refs.membersInvite.textArea = '';
    },
  },
};
</script>

<style lang="scss">
.add-member-modal {
  .add-member-popup {
    width: 600px;
    overflow: hidden;
    @apply bg-important-bg border border-solid border-important-border;
  }
  .custom-header-icon {
    @apply mr-4;
  }
  .popup-title-wrap {
    .popup-title-right {
      .popup-title-text {
        @apply tracking-normal;
      }
    }
  }
  &:focus {
    @apply outline-none;
  }
}
</style>
