<template>
  <div class="tooltip-wrap">
    <span class="tooltip-text">{{ tooltipText }}</span>
    <div
      v-if="tooltipMeta.icon"
      class="tooltip-icons-wrap"
    >
      <div
        v-if="orIcon"
        class="key-wrap"
      >
        <em
          :style="getCustomIconStyle(iconPath(orIcon))"
          class="custom-icons bg-icon-default"
        />
      </div>

      <strong
        v-if="orIcon"
        class="ml-1"
      >
        /
      </strong>

      <span
        v-if="tooltipMeta.icon.ctrl && !tooltipMeta.icon.modifiers"
        class="key-wrap cmd-symbol"
      >
        {{ getIcon('ctrl') }}
      </span>
      <span
        v-for="modifier in tooltipMeta.icon.modifiers"
        :key="modifier"
        class="key-wrap cmd-symbol"
      >
        {{ getIcon(modifier) }}
      </span>
      <em
        v-if="tooltipMeta.icon.key"
        class="key-wrap"
      >
        {{ tooltipMeta.icon.key }}
      </em>
    </div>
  </div>
</template>

<script>
import checkPlatform from '@/mixins/checkPlatform';
import customIconsMixin from '@/mixins/customIconsMixin';

export default {
  name: 'TooltipTemplate',
  mixins: [checkPlatform, customIconsMixin],
  props: {
    tooltipText: {
      type: String,
      required: false,
      default: '',
    },
    tooltipMeta: {
      type: Object,
      required: false,
      default: () => ({
        icon: {
          type: Object,
        },
      }),
    },

    orIcon: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      ctrlEntity: '&#8984;',
    };
  },

  computed: {
    cmdIcon() {
      return this.isApple ? '&#8984;' : 'Ctrl';
    },
  },

  methods: {
    decodedEntity(entity) {
      const textarea = document.createElement('textarea');
      textarea.innerHTML = entity;
      return textarea.value;
    },

    getIcon(key) {
      switch (key.toLowerCase()) {
        case 'ctrl':
        case 'cmd':
          if (!this.isApple) return 'Ctrl';
          return this.decodedEntity(this.ctrlEntity);
        case 'shift':
          return this.decodedEntity('&#8679;');
        default:
          return '';
      }
    },
  },
};
</script>

<style lang="scss">
.tooltip-wrap {
  display: flex;
  align-items: center;
  .tooltip-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    margin-right: 4px;
  }
  .tooltip-icons-wrap {
    display: flex;
  }
}
</style>
