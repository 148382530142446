<template functional>
  <span
    class="emoji-icon"
    :class="props.classes"
    :style="{
      fontSize: '14px',
      width: '16px',
      height: '16px',
      textAlign: 'center',
      ...props.customStyle,
    }"
    >{{ props.emoji }}</span
  >
</template>

<script>
export default {
  props: {
    emoji: {
      type: String,
      required: true,
    },
    classes: {
      type: String,
      required: false,
      default: '',
    },
    customStyle: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
};
</script>
