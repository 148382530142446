<template>
  <div class="toggle-button">
    <label
      :class="{ checked: value, disabled }"
      class="switch-wrap pt"
    >
      <input
        v-model="value"
        type="checkbox"
        :disabled="disabled"
        @change="changed"
      />
      <div class="switch" />
    </label>
    <div
      v-if="toggleLabelKey"
      class="label-text action-font"
    >
      {{ translate(toggleLabelKey) }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToogleButton',
  props: {
    defaultValue: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    toggleLabelKey: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      value: this.defaultValue,
    };
  },
  watch: {
    defaultValue() {
      this.value = this.defaultValue;
    },
  },
  methods: {
    changed() {
      this.$emit('click', this.value);
    },
    toggleValue() {
      this.value = !this.value;
    },
  },
};
</script>

<style lang="scss">
$width: 36px;
$padding: 0 0 0 8px;
$height: 20px;
$borderRadius: 12px;
$toggleWidth: 50%;
$circleSize: 16px;

.toggle-button {
  display: inline-flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  padding: $padding;

  .switch-wrap {
    display: inline-flex;
    background: $gray100;
    transition: background 0.2s;
    width: $width;
    height: $height;
    border-radius: $borderRadius;
    vertical-align: middle;
    @apply bg-important-bg border border-solid border-important-border;

    input {
      position: absolute;
      opacity: 0;
      width: 0;
      height: 0;
    }

    .switch {
      width: $width;
      height: 100%;
      display: flex;
      transition: 0.2s;
      position: relative;

      &::after {
        position: absolute;
        content: '';
        box-shadow:
          0px 1px 3px rgba($gray900, 0.1),
          0px 1px 2px rgba($gray900, 0.06);
        border-radius: 50%;
        grid-column: 2;
        transition: all 0.2s;
        width: $circleSize;
        height: $circleSize;
        top: 2px;
        left: 2px;
        @apply bg-text-default;
      }
    }
    &.disabled {
      .switch {
        &::after {
          @apply bg-inactive-text;
        }
      }
    }

    &.checked {
      background: $stBlue;

      .switch {
        &::after {
          left: calc(100% - #{$toggleWidth});
        }
      }
    }
  }

  .label-text {
    font-family: $monospaceFontFamily;
    color: #000;
    margin-left: 8px;
    font-weight: 700;
    font-size: 16px;
  }

  // style for toggle buttons used in views
  &.view-toggle-button {
    .label-text {
      font-family: inherit;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.2px;
      color: $stLightBlack;
    }
  }
}
</style>
