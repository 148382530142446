<template>
  <div
    class="user-avatar-main"
    :class="[size, { 'hidden-avatar': hidden }]"
    @mouseenter="checkMemberHover"
  >
    <UserAvatarContent
      v-if="userId"
      :thumbnail-image="memberInTeamMembers.profile_image || ''"
      :member-initials="memberInitials || ''"
      :member-id="userId"
      :fallback-style="fallbackStyle"
      :size="size"
      :color="memberColor"
      :inactive="inactive"
      :hidden-member="hidden"
    >
      <em
        v-if="hidden"
        class="custom-icon flex w-full h-full hidden-member-icon"
        :style="{ ...getCustomIconStyle(iconPath('user')), ...hiddenStyle }"
      />
    </UserAvatarContent>
    <GenericUserAvatarIcon
      v-else
      :icon="genericUserAvatar.icon"
      :tooltip="genericUserAvatar.tooltip"
      class="mr-0 bg-transparent border border-solid border-dividers-default box-border"
    />
  </div>
</template>

<script>
import UserAvatarContent from '@/components/functional/UserAvatarContent';
import userAvatarContentMixin from '@/mixins/userAvatarContentMixin';
import memberHoverPreviewMixin from '@/mixins/memberHoverPreviewMixin';
import customIconMixin from '@/mixins/customIconsMixin';
import GenericUserAvatarIcon from '@/components/elements/GenericUserAvatarIcon';

export default {
  name: 'UserAvatar',
  components: {
    UserAvatarContent,
    GenericUserAvatarIcon,
  },
  mixins: [userAvatarContentMixin, memberHoverPreviewMixin, customIconMixin],
  props: {
    member: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    size: {
      type: String,
      required: false,
      default: 'small',
    },
    showUserColor: {
      type: Boolean,
      required: false,
      default: false,
    },
    inactive: {
      type: Boolean,
      required: false,
      default: false,
    },
    groupAvatarId: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    getHoverMember() {
      const member = this.$store.getters.getMemberById(this.userId);
      if (this.isGuestRole && !Object.keys(member).length) {
        return { id: this.userId };
      }
      return member;
    },
    genericUserAvatar() {
      return this.member?.email ? { icon: 'mail', tooltip: this.member?.tooltip } : {};
    },
  },
  methods: {
    checkMemberHover($event) {
      if (!this.userId) return;
      this.displayMemberHoverPreview($event, this.getHoverMember, this.groupAvatarId);
    },
  },
};
</script>
