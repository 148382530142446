<template>
  <div
    class="add-member-content"
    :class="{ 'popup-form-padding': !isLoginPage }"
  >
    <p
      v-if="isOnboardingInfo"
      class="onboarding_form_description"
    >
      {{ translate('STIsMostUseful') }}
    </p>
    <p
      v-if="isOnboardingInfo"
      class="mb-[6px] font-medium text-text-default leading-6"
    >
      {{ translate('emailAdresses') }}
    </p>
    <div
      ref="memberSearchInput"
      class="member-search-input"
    >
      <div class="autoexpand-email-area">
        <vue-scroll
          :ops="{
            scrollPanel: { scrollingX: false },
            rail: { gutterOfSide: '4px', gutterOfEnds: '8px' },
            bar: { size: '4px', keepShow: true },
          }"
          class="autoexpand-email-area-input"
          :class="{
            'not-valid': validateEmails,
          }"
        >
          <AutoExpandTextarea
            ref="quickCardTitleInput"
            v-model="textArea"
            :text-value="textArea"
            :focus-field="true"
            :textarea-class="''"
            :initial-height="60"
            :placeholder="translate('person1Email')"
            :disabled="!isElevated"
            @esc="closePopup"
          />
        </vue-scroll>
      </div>
      <span
        class="section-sublabel w-full opacity-0 mt-2"
        :class="{ 'opacity-100': !isOnboardingInfo && existingMembersAdded }"
        >{{ translate('alreadyMembersOfWorkspace') }}</span
      >
      <span class="section-label usn">{{ translate('inviteAs') }}</span>
      <ListDropdownWrap
        ref="roleDropdown"
        :dropdown-width="dropdownWidth"
        :height="44"
        class="w-full mb-2 border-important-border"
        :items="accountTypes"
        :selected-item="selectedBoardIndex"
        :disable-scroll="true"
        :disabled="!isElevated"
        @item-click="handleRoleClick"
      />
      <div class="lower-members-invite-form-part">
        <span class="section-sublabel">{{ accountTypesExplanation }}</span>
        <span class="section-label usn">{{ translate('addToSpaces') }}</span>
        <span
          v-if="guestRole"
          class="section-sublabel mb-1"
          :class="{
            'text-red-600': invitedGuestWithoutProject,
          }"
        >
          {{ translate('guestsMustBeAddedToOneSpace') }}
        </span>
        <div class="w-full flex items-center gap-2 max-w-[552px] h-full flex-wrap">
          <div
            v-for="(project, index) in selectedProjects"
            :key="index"
          >
            <div class="added-projects">
              <ProjectIcon
                class="h-4 w-4 mr-1"
                :project-icon="project.icon || ''"
                :project-color="project.customIconColor || ''"
                :type="project.iconType || ''"
              />
              <span
                class="project-title text-text-default whitespace-nowrap overflow-hidden text-ellipsis usn"
              >
                {{ projectPillTitle(index) }}
              </span>
              <em
                v-if="project.is_private"
                v-tooltip="{
                  content: translate('thisSpaceIsPrivate'),
                  trigger: 'hover',
                  classes: 'black-tooltip sd-pr-icn private-space-icon mt-19',
                  container: false,
                  placement: 'bottom',
                  boundariesElement: 'window',
                }"
                class="custom-icons private-icon"
                :style="[getCustomIconStyle(iconPath('private-project'))]"
              />
              <em
                class="material-icons text-text-default h-4 w-4 ml-1 text-base leading-[18px] color-icon-default cursor-pointer"
                @click="removeProject(index)"
              >
                close
              </em>
            </div>
          </div>
          <ButtonCustom
            ref="filterButton"
            class="text-text-default hover:bg-hover-default"
            :class="{ 'button-with-text': addButtonText !== '' }"
            :text="addButtonText"
            :type="'important-custom'"
            :variation="'outlined'"
            :button-dimensions="{
              width: addButtonText === '' ? buttonCustomWidth : 'max-content',
              height: 24,
            }"
            :label-left="true"
            :custom-icon-name="'plus'"
            :custom-icon-size="'12px'"
            :theme-style="true"
            :disabled="!isElevated"
            @click="projectDropdownClickHandler"
          />
          <v-popover
            :open="showProjectDropdown"
            :trigger="'manual'"
            :popover-class="'popover-dropdown pt-2 pb-4 pl-5'"
          >
            <template slot="popover">
              <div
                v-click-outside="closeDropdown"
                class="projects-dropdown-wrap"
              >
                <div class="projects-header-wrap projects-search usn">
                  <input
                    ref="searchInput"
                    v-model="searchValue"
                    :placeholder="`${translate('addToSpaces')}...`"
                    class="projects-header"
                    type="text"
                    @keyup="checkKey"
                    @compositionupdate="compositionUpdate"
                    @keydown="checkDownKey"
                  />
                </div>
                <vue-scroll
                  ref="projectsScroll"
                  class="projects-list"
                  :class="{ scrolling: scrolling }"
                  :ops="{
                    scrollPanel: { scrollingX: false },
                    bar: { keepShow: true, size: '4px' },
                    rail: { gutterOfSide: '2px' },
                  }"
                >
                  <div
                    v-if="projectsNotFound"
                    class="projects-notfound-wrap"
                  >
                    <p class="text-text-default">{{ translate('noProjectFound') }}</p>
                  </div>
                  <div
                    v-for="(project, index) in filteredProjects"
                    :key="index"
                    @mousedown.prevent
                  >
                    <FilterDropdownItem
                      :index="index"
                      :item="project"
                      :items="filteredProjects"
                      :selected-item-index="selectedIndex"
                      @item-hover="itemHover(index)"
                      @scroll-to-item="scrollToItem"
                      @item-click="handleProjectClick(project, index)"
                      @item-right-side-click="itemRightSideClick(project, index)"
                    />
                  </div>
                </vue-scroll>
              </div>
            </template>
          </v-popover>
        </div>
      </div>
      <section
        v-if="!isElevated"
        class="w-full"
      >
        <Box
          class="relative w-full flex flex-row content-center border-blue-500 bg-highlight-bg mt-4 py-2 px-4"
        >
          <em
            :style="getCustomIconStyle(iconPath('info'))"
            class="h-4 w-4 custom-icons page-toolbar self-center dark:bg-blue-300 bg-blue-600 ml-0 mr-2"
          />
          <Header
            :title="{
              value: translate('invitingPeopleToWorkspace'),
              class: 'text-sm font-normal pt-1.5',
            }"
          ></Header>
        </Box>
      </section>
    </div>
    <section v-if="!freePlan">
      <Box
        v-if="canceledRenewal"
        class="relative w-full flex flex-row content-center border-red-500 bg-destructive-bg mt-4 py-2 px-4"
      >
        <em
          :style="getCustomIconStyle(iconPath('info'))"
          class="h-4 w-4 custom-icons page-toolbar self-center dark:bg-red-300 bg-red-600 ml-0 mr-2"
        />
        <Header
          :title="{
            value: translate('billing.yourCurrentPlanIsNotSetToRenew'),
            class: 'text-sm font-normal',
          }"
        >
          <template #subtitle>
            <h2 class="text-xs leading-[1.125rem] font-normal">
              {{ translate('billing.youCanStillInvite') }}
              <b>{{ billingDetails.subscription.seat_count }}</b>
              {{ translate('billing.workspaceMembers') }}.
            </h2>
          </template>
        </Header>
      </Box>
      <Box
        v-else
        class="relative w-full flex flex-row content-center border-blue-500 bg-highlight-bg mt-4 py-2 px-4"
      >
        <em
          :style="getCustomIconStyle(iconPath('info'))"
          class="h-4 w-4 custom-icons page-toolbar self-center dark:bg-blue-300 bg-blue-600 ml-0 mr-2 flex-shrink-0"
        />
        <Header
          :title="{
            value: translate('billing.weBillTheWorkspacePayment'),
            class: 'text-sm font-normal',
          }"
          :subtitle="{
            value: translate('billing.checkThePlanPanel'),
            class: 'text-xs leading-[1.125rem] font-normal',
          }"
        ></Header>
      </Box>
    </section>
    <div class="flex mt-[24px] pb-6 flex-row justify-end footer-buttons-members-invite">
      <ButtonCustom
        ref="cancelButton"
        :text="translate('cancel')"
        :type="'popup-button-wrap important'"
        :button-dimensions="{
          width: 100,
          height: 36,
        }"
        @click="handleClose"
      />
      <div class="empty-separator" />
      <ButtonCustom
        ref="sendButton"
        :text="translate('send')"
        :type="'popup-button-wrap attention'"
        :disabled="validateEmails || emptyInput"
        :button-dimensions="{
          width: 100,
          height: 36,
        }"
        @click="inviteUser"
      />
    </div>
  </div>
</template>

<script>
import validateEmail from '@/utilities/validateEmail';
import ButtonCustom from '@/components/elements/ButtonCustom';
import AutoExpandTextarea from '@/components/elements/AutoExpandTextarea';
import customIconsMixin from '@/mixins/customIconsMixin';
import ListDropdownWrap from '@/components/widgets/ListDropdownWrap';
import FilterDropdownItem from '@/components/widgets/filters/FilterDropdownItem';
import inputDropdown from '@/mixins/inputDropdown';
import EventBus from '@/utilities/eventBus';
import ProjectIcon from '@/components/elements/common/ProjectIcon';
import Box from '@/components/sections/settings/Box';
import Header from '@/components/sections/settings/Header';
import { onMounted, computed, ref, getCurrentInstance } from 'vue';
import { api } from '@/utilities/api';
import useCurrentRole from '@/utilities/composables/useCurrentRole';
import getCurrentDateInSeconds from '@/utilities/getCurrentDateInSeconds';

export default {
  name: 'MembersInviteForm',
  components: {
    ButtonCustom,
    AutoExpandTextarea,
    ListDropdownWrap,
    FilterDropdownItem,
    ProjectIcon,
    Box,
    Header,
  },
  mixins: [customIconsMixin, inputDropdown],
  setup() {
    const _this = getCurrentInstance()?.proxy;

    /**
     * @type {import('vue').Ref<import('@superthread-com/api').GetSubscriptionResponse | null>}
     */
    const billingDetails = ref(null);
    onMounted(async () => {
      billingDetails.value = (await api.auth.getSubscription(_this.$store.getters.getTeamID)).data;
    });
    const freePlan = computed(() => {
      if (!billingDetails.value?.subscription?.plan_id) return true;
      return billingDetails.value?.subscription?.plan_id === 'free';
    });
    const canceledRenewal = computed(
      () =>
        billingDetails.value?.subscription?.status === 'active' &&
        billingDetails.value?.subscription?.plan_id !== 'free' &&
        billingDetails.value?.subscription?.cancel_at !== 0
    );
    const { isElevated } = useCurrentRole();

    return {
      billingDetails,
      freePlan,
      canceledRenewal,
      isElevated,
    };
  },
  data() {
    return {
      showProjectDropdown: false,
      searchValue: '',
      textArea: '',
      selectedIndex: 0,
      selectedBoardIndex: 1,
      scrolling: false,
      focusTimeout: null,
      selectedProjects: [],
      dropdownWidth: 0,
      guestRole: false,
      invitedGuestWithoutProject: false,
    };
  },
  computed: {
    sortedProjects() {
      return this.$store.getters.getTeamSortedProjects;
    },
    dropdownItems() {
      return this.sortedProjects.map((project) => ({
        id: project.id,
        label: project.title,
        checkbox: true,
        checked: this.selectedProjects.some((p) => p.id === project.id),
        icon: project?.icon?.src,
        customIcon: project?.icon?.src,
        customIconColor: project?.icon?.color,
        iconType: project?.icon?.type || this.iconEnum,
        privateItem: project?.is_private,
      }));
    },
    currentProject() {
      return this.$store.getters.getCurrentProject;
    },
    isSettings() {
      return this.$route.path.startsWith(`/${this.teamSlug}/settings/`);
    },
    teamSlug() {
      return this.$store.getters.getTeamSlug;
    },
    projectsIds() {
      const projects = [...this.selectedProjects];
      return projects.map((project) => project.id);
    },
    filteredProjects() {
      return this.dropdownItems.filter((p) =>
        p.label?.toLowerCase().includes(this.searchValue.toLowerCase())
      );
    },
    addButtonText() {
      return this.selectedProjects.length ? '' : this.translate('addSpaces');
    },
    inviteeRole() {
      return this.$store.getters.getInviteeRole;
    },
    inviteeProjectId() {
      return this.$store.getters.getInviteesProjectIds;
    },
    noProjectsIds() {
      return !this.selectedProjects.length;
    },
    emptyInput() {
      return this.textArea === '';
    },
    isLoginPage() {
      return this.$route.name && (this.$route.name === 'login' || this.$route.name === 'create');
    },
    // removes white spaces from start and end of string
    // then convert all special characters to comma
    // then split string by comma
    // then removes empty strings
    // then map array to object with email and valid properties
    splitedEmails() {
      return !this.emptyInput
        ? (this.textArea ?? '')
            .trim()
            .replace(/(\s|[,;])+/g, ',')
            .split(',')
            .filter(
              (item, index, self) => item.length && index === self.findIndex((i) => i === item)
            )
            .map((i) => ({ email: i, valid: validateEmail(i) }))
        : [];
    },

    existingMembersAdded() {
      return this.splitedEmails.some(({ email }) =>
        Object.keys(this.currentTeamMembers).some(
          (memberId) => this.currentTeamMembers[memberId].email === email
        )
      );
    },

    projectsNotFound() {
      return this.searchNotEmpty && !this.dropdownItems.length;
    },
    searchNotEmpty() {
      return this.searchValue !== '';
    },
    membersSearchInput() {
      return this.$store.getters.getMembersSearchInput;
    },
    currentRole() {
      return this.$store.getters.getCurrentRole;
    },
    validateEmails() {
      return this.splitedEmails.some((email) => !email.valid);
    },
    isOnboardingInfo() {
      return this.$route.name === this.$constants.routeNames.onboardingInfo;
    },
    accountTypes() {
      return [
        {
          id: 'member',
          label: this.translate('member'),
          disabled: !this.inviteMembersFeatureAvailable,
          ...(!this.inviteMembersFeatureAvailable && {
            badge: {
              icon: 'lock',
              text: 'upgradePlan',
            },
          }),
        },
        // add admin type only if current role is admin or owner
        ...(['admin', 'owner'].includes(this.currentRole) || this.isOnboardingInfo
          ? [
              {
                id: 'admin',
                label: this.translate('admin'),
              },
            ]
          : []),
        ...(!this.isOnboardingInfo
          ? [
              {
                id: 'guest',
                label: this.translate('guest'),
                disabled: !this.inviteMembersFeatureAvailable,
                ...(!this.inviteMembersFeatureAvailable && {
                  badge: {
                    icon: 'lock',
                    text: 'upgradePlan',
                  },
                }),
              },
            ]
          : []),
      ];
    },
    searchResults() {
      return this.filteredProjects;
    },
    redirectProjectId() {
      return this.selectedProjects[0]?.id;
    },
    buttonCustomWidth() {
      return this.selectedProjects.length ? 24 : 108;
    },
    accountTypesExplanation() {
      switch (this.inviteeRole) {
        case 'member':
          return this.translate('memberExplanation');
        case 'admin':
          return this.translate('adminExplanation');
        case 'guest':
          return this.translate('guestExplanation');
        default:
          return '';
      }
    },
    settingsCurrentProjectId() {
      return this.$store.getters.getSettingsCurrentProjectId;
    },
    settingsCurrentProject() {
      return this.sortedProjects.find((p) => p.id === this.settingsCurrentProjectId);
    },

    inviteMembersFeatureAvailable() {
      return this.$store.getters.getTeamFeatureEnabled(
        this.$constants.featureFlagIds.inviteMembers
      );
    },
    currentTeamMembers() {
      return this.$store.getters.getTeamMembers;
    },
  },
  watch: {
    textArea(newVal) {
      if (newVal === '') {
        EventBus.$emit('disableNextButton', true);
      } else {
        EventBus.$emit('disableNextButton', this.validateEmails);
      }
    },
  },
  created() {
    const teamProjects = this.$store.getters.getTeamProjects;
    const hasTeamProjects = teamProjects.length;
    if (hasTeamProjects) {
      this.addCurrentProjectPill();
    }
    this.$store.dispatch('loadTeamProjects');
  },
  mounted() {
    this.textArea = this.membersSearchInput;
    this.$nextTick(() => {
      this.$store.dispatch('setInviteeRole', this.accountTypes[1]?.id ?? 'admin');
      const dropdown = this.$refs.roleDropdown.$el;
      this.dropdownWidth = dropdown.offsetWidth;
      // add failed invites to textarea
      if (this.$store.getters.failedInvites.length) {
        const delimiter = ', ';
        const failedInvites = this.$store.getters.failedInvites
          .map((invitees) => invitees.map?.((i) => i.invitee_email).join(delimiter))
          .join(delimiter);
        this.textArea = failedInvites;
        this.$store.dispatch('setFailedInvites', []);
      }
      if (this.isOnboardingInfo) this.$store.dispatch('setInviteeRole', 'admin');
    });
    window.addEventListener('keypress', this.handleEnter);
    window.addEventListener('resize', this.setDropdownWidth);
    EventBus.$on('inviteOtherPeople', this.inviteUser);
  },
  destroyed() {
    if (this.inputFocusTimeout) {
      clearTimeout(this.inputFocusTimeout);
    }
    window.removeEventListener('keypress', this.handleEnter);
    window.removeEventListener('resize', this.setDropdownWidth);
    EventBus.$off('inviteOtherPeople', this.inviteUser);
  },
  methods: {
    addCurrentProjectPill() {
      let project = this.currentProject;
      if (this.isSettings) {
        project = this.settingsCurrentProject;
      }
      if (project?.id) {
        this.selectedProjects.push({
          id: project.id,
          label: project.title,
          checkbox: true,
          checked: true,
          icon: project.icon?.src ?? '',
          customIcon: project.icon?.src ?? '',
          customIconColor: project.icon?.color ?? '',
          iconType: project.icon?.type || this.iconEnum,
          is_private: project.is_private || false,
        });
        this.$store.dispatch('setInviteesProjectIds', [project.id]);
      }
    },
    setDropdownWidth() {
      const dropdown = this.$refs.roleDropdown.$el;
      this.dropdownWidth = dropdown.offsetWidth;
    },
    projectPillTitle(index) {
      return this.selectedProjects[index]?.label;
    },
    handleEsc(event) {
      event.stopPropagation();
      this.closeDropdown();
      this.$emit('focusPopup');
    },
    focusInput() {
      clearTimeout(this.inputFocusTimeout);
      this.focusTimeout = setTimeout(() => {
        if (this.$refs.searchInput) {
          this.$refs.searchInput.focus();
        }
      }, 100);
    },
    handleEnter(event) {
      if (
        (event.keyCode === 13 || event.which === 13) &&
        !this.emptyInput &&
        !this.showProjectDropdown &&
        this.validateEmail
      ) {
        this.inviteUser();
      }
    },
    closePopup() {
      this.$emit('close');
    },
    iconStyle(projectIcon) {
      return {
        ...this.getCustomIconStyle(this.iconPath(projectIcon.src || projectIcon.icon || '')),
        background: projectIcon.color || projectIcon.customIconColor,
      };
    },
    projectDropdownClickHandler() {
      this.showProjectDropdown = !this.showProjectDropdown;
      this.focusInput();
    },
    closeDropdown(e) {
      if (e?.target?.className?.includes('button')) return;
      this.showProjectDropdown = false;
    },
    handleProjectClick(item, index) {
      if (index !== -1) {
        item.checked = !item.checked;
        if (item.checked) {
          if (this.guestRole) this.invitedGuestWithoutProject = false;
          const isPrivate = this.$store.getters.getProjectById(item.id).is_private;
          this.selectedProjects.push({ ...item, is_private: isPrivate });
        } else {
          const itemIndex = this.selectedProjects.indexOf(item);
          this.selectedProjects.splice(itemIndex, 1);
        }
        this.$store.dispatch('setInviteesProjectIds', this.projectsIds);
      }
    },
    handleRoleClick(item, index) {
      if (item.disabled) {
        this.$store.dispatch(
          'setShowUpgradePlanPopup',
          this.$constants.featureFlagIds.userPermissions
        );
        return;
      }
      if (item.id === 'guest') {
        this.guestRole = true;
        this.$store.dispatch('setInviteesProjectIds', this.projectsIds);
      } else {
        this.guestRole = false;
        this.invitedGuestWithoutProject = false;
      }
      if (index !== -1 && index !== this.selectedBoardIndex) {
        this.selectedBoardIndex = index;
        this.$store.dispatch('setInviteeRole', item.id);
      }
    },
    removeProject(index) {
      const projectId = this.selectedProjects[index].id;
      const projectIndexInProjectIds = this.projectsIds.findIndex((pId) => pId === projectId);
      this.projectsIds.splice(projectIndexInProjectIds, 1);
      this.$store.dispatch('setInviteesProjectIds', this.projectsIds);
      this.selectedProjects.splice(index, 1);
      const projectItemIndex = this.dropdownItems.findIndex((p) => p.id === projectId);
      if (projectItemIndex !== -1) {
        this.dropdownItems[projectItemIndex].checked = false;
      }
    },
    handleClose() {
      if (this.$refs.sendButton || this.$refs.cancelButton) {
        this.closeDropdown();
        this.closePopup();
      }
      this.textArea = '';
    },
    inviteUser() {
      const alreadyInWorkspaceUsers = [];
      if (this.guestRole && this.noProjectsIds) {
        this.invitedGuestWithoutProject = true;
        return;
      }

      const membersToAdd = this.splitedEmails.map((user) => ({
        invitee_email: `${user.email}`,
        invitee_role: `${this.inviteeRole}`,
      }));

      // adds invited users locally to the pending invite list
      EventBus.$emit('invitationsSent', membersToAdd);

      const invitees = [];
      this.splitedEmails.forEach((user) => {
        let userObject = {};
        Object.keys(this.currentTeamMembers).forEach((memberId) => {
          if (this.currentTeamMembers[memberId].email === user.email) {
            userObject = this.currentTeamMembers[memberId];
          }
        });

        if (userObject.email === user.email) {
          alreadyInWorkspaceUsers.push({
            user_id: userObject.id,
            assigned_date: getCurrentDateInSeconds(),
          });
        } else {
          invitees.push({
            invitee_email: user.email,
            invitee_role: this.inviteeRole,
            redirect_project_id: this.redirectProjectId,
            ...(this.inviteeProjectId?.length && {
              project_ids: this.$store.getters.getInviteesProjectIds,
            }),
          });
        }
      });

      // split invitees into chunks of 8 as the backend can only handle 8 at a time
      const inviteesChunks = [];
      const maxInvitesPerRequests = 8;
      for (let i = 0; i < invitees.length; i += maxInvitesPerRequests) {
        inviteesChunks.push(invitees.slice(i, i + maxInvitesPerRequests));
      }

      this.sendInvites({ inviteesChunks });

      this.$store.dispatch('setRedirectProjectId', this.redirectProjectId);

      // add alreadyInWorkspaceUsers to selected projects
      this.selectedProjects.forEach((project) => {
        this.$store.dispatch('addMemberToProject', {
          membersToAdd: alreadyInWorkspaceUsers,
          projectId: project.id,
        });
      });
      this.handleClose();
    },
    sendInvites({ inviteesChunks, isRetry = false }) {
      const invitePromises = [];
      const failedInvites = [];
      // send invitees in promises
      inviteesChunks.forEach((invitees) => {
        const promise = this.$store
          .dispatch('inviteUser', {
            invitees,
          })
          .then(({ team_invites }) => {
            EventBus.$emit('updatePendingInvitesLocal', team_invites);
          })
          .catch(() => {
            failedInvites.push(invitees);
          });
        invitePromises.push(promise);
      });

      // show error message if any invite failed
      return Promise.all(invitePromises).then(() => {
        if (!failedInvites.length) {
          this.$store.dispatch('addUiNotification', {
            message: this.translate('invitationsSent'),
            duration: 4000,
          });
        } else if (!isRetry) {
          this.$store.dispatch('addUiNotification', {
            message: this.translate('invitationsFailed'),
            status: this.$constants.uiNotificationStatuses.warning,
            duration: 4000,
            labels: [
              {
                text: this.translate('retry'),
                type: this.$constants.uiNotificationElements.button,
                callback: this.sendInvites,
                params: { inviteesChunks: failedInvites, isRetry: true },
                buttonStyle: 'important',
              },
            ],
          });
        } else {
          // open members invite form again with failed invites
          this.$store.dispatch('setAddMemberPopup', true);
          // save failedInvites to store
          this.$store.dispatch('setFailedInvites', failedInvites);
        }
      });
    },
    itemHover(index) {
      this.setSelectedIndex(index);
    },
    itemRightSideClick(item, index) {
      this.handleProjectClick(item, index);
      this.closeDropdown();
      this.$emit('focusPopup');
    },
    selectSearchResult(item, index) {
      this.handleProjectClick(item, index);
      this.closeDropdown();
      this.$emit('focusPopup');
    },
    selectOnlySearchResult(item, index) {
      this.handleProjectClick(item, index);
    },
    scrollToItem({ node: itemNode }) {
      const { offsetTop: nodeTop, offsetHeight: nodeHeight } = itemNode;
      const scrollBounds = this.$refs.projectsScroll.$el.getBoundingClientRect();
      const { scrollTop } = this.$refs.projectsScroll.getPosition();

      if (nodeTop < scrollTop) {
        this.$refs.projectsScroll.scrollTo({ y: nodeTop }, 200);
        return;
      }

      if (nodeTop + nodeHeight > scrollBounds.height + scrollTop) {
        this.$refs.projectsScroll.scrollTo(
          {
            y: nodeTop + nodeHeight - scrollBounds.height,
          },
          200
        );
      }
    },
  },
};
</script>

<style lang="scss">
.add-member-content {
  @apply flex
  flex-col
  bg-background-strongest;

  &.popup-form-padding {
    padding: 0px 24px 0px 24px;
  }

  .member-search-input {
    .lower-members-invite-form-part {
      @apply w-full;
    }
    @apply relative
      float-left
      w-full
      flex
      items-end
      pb-[19px]
      flex-col
      border-b
      border-0
      border-solid
      border-dividers-default;

    .input-wrap {
      @apply w-full
      h-full;
    }

    .section-sublabel {
      @apply w-full flex justify-start text-xs leading-[18px] font-normal text-text-default;
    }
  }
  .button-with-text {
    .button-icon {
      margin-left: 4px !important;
    }
  }
  .outlined {
    @apply py-0.5
    px-2
    shadow-none;
    .button-icon {
      &.custom {
        @apply w-full flex justify-center;
        margin-left: 0px !important;
      }
    }
  }
  .dropdown-wrap {
    .dropdown-closed {
      @apply border-important-border;
    }
    .dropdown-opened {
      @apply border-important-focusedBorder;
    }
  }
  .section-label {
    @apply flex
    w-full
    font-medium
    text-sm
    mb-1
    mt-8
    text-text-default;
  }
  .added-projects {
    @apply flex
    items-center
    h-[22px]
    pr-2
    pl-1.5
    max-w-fit
    border
    border-solid
    border-important-border
    rounded-2xl;
    .project-title {
      @apply text-xs
      font-medium
      ml-1
      leading-[18px];
    }
    .remove-project-icon {
      @apply text-icon-default
      text-[12px]
      min-w-[12px]
      max-w-[12px]
      overflow-hidden
      hidden
      font-black;
    }
  }
  @media only screen and (max-width: 768px) {
    .member-search-input {
      position: relative;
      float: left;
      width: 100%;
      display: flex;
      align-items: flex-end;
      flex-direction: column;

      .input-wrap {
        height: 100%;
        width: 100%;
      }
    }
  }
}
.not-valid {
  box-shadow: 0px 0px 0px 2px #f97066;
}
.projects-list {
  @apply flex
  box-border
  flex-col
  overflow-hidden;

  &.scrolling {
    .filter-dropdown-item {
      @apply pointer-events-none;
    }
  }

  .__panel {
    @apply pb-1;

    .projects-notfound-wrap {
      @apply bg-inactive-bg
      flex
      flex-col
      p-3
      text-sm
      my-1.5
      mx-3
      rounded-lg;
    }
  }
}
.autoexpand-email-area {
  @apply h-[88px]
    flex
    w-full
    rounded-lg
    border
    border-solid
    border-important-border
    flex-col;

  .autoexpand-email-area-input {
    @apply flex
    flex-col
    max-h-[120px]
    rounded-lg
    box-border
    overflow-hidden
    bg-important-bg;

    textarea {
      @apply text-base bg-standard-bg text-text-default;
    }

    .__view {
      @apply px-3.5
      py-2.5;
    }
  }
}
.projects-dropdown-wrap {
  @apply w-[250px]
  flex
  flex-col
  max-h-[370px]
  h-auto
  rounded-lg
  bg-important-bg
  overflow-hidden
  shadow-md
  border
  border-solid
  border-important-border;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.16));
  will-change: filter;

  .projects-header-wrap {
    @apply relative
    w-full
    box-border
    flex
    items-center
    py-3
    px-4
    border
    rounded-t-lg
    border-solid
    border-dividers-default;

    &.projects-search {
      @apply cursor-text;
    }
    .projects-header {
      @apply border-none
      box-border
      outline-none
      font-normal
      text-sm
      w-full
      text-text-default
      bg-important-bg;

      &::placeholder {
        @apply text-text-placeholder;
      }
    }
  }
  .item-label {
    @apply font-normal;
  }
}
</style>
