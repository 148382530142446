var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dropdown-wrap"},[(_vm.items.length)?_c('v-popover',{attrs:{"open":_vm.showDropdown,"trigger":"click","placement":_vm.dropdownPlacement,"popover-class":'popover-dropdown list-dropdown-popover'}},[_c('template',{slot:"popover"},[(_vm.showDropdown)?_c('ListDropdown',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeDropdown),expression:"closeDropdown"}],staticClass:"list-dropdown-popover dropdown-position",style:(_vm.dropdownStyle),attrs:{"items":_vm.items,"position":_vm.dropdownPosition,"width":_vm.dropdownWidth,"selected-item":_vm.selectedItem,"disable-scroll":_vm.disableScroll},on:{"click":_vm.handleItemClick,"closeEscape":_vm.closeDropdown}}):_vm._e()],1),_c('div',{ref:"dropdownTrigger"},[_vm._t("trigger",function(){return [_c('div',{class:_vm.dropdownClass,style:(_vm.dropdownWrapStyle),on:{"click":_vm.toggleDropdown}},[_c('div',{staticClass:"label-wrap",class:{ 'without-label': !_vm.label }},[(_vm.label)?_c('span',{staticClass:"dropdown-label"},[_vm._v(" "+_vm._s(_vm.label))]):_vm._e(),_c('span',{class:{
                'dropdown-selected-disabled text-inactive-text': _vm.disabled,
                'dropdown-selected': !_vm.disabled,
                'text-text-placeholder': _vm.selectedItem === -1,
              }},[_vm._v(" "+_vm._s(_vm.selectedItemText)+" ")])]),(!_vm.disabled)?_c('em',{class:_vm.showDropdown
                ? 'custom-icons dropdown-wrap-arrow-down'
                : 'custom-icons dropdown-wrap-arrow-down rotate-180',style:(_vm.getCustomIconStyle(_vm.iconPath('chevron-up')))}):_vm._e()])]},{"toggle":_vm.toggleDropdown})],2)],2):_c('div',{ref:"dropdownTrigger",staticClass:"dropdown-button usn empty-list",style:(_vm.dropdownWrapStyle),on:{"click":_vm.toggleDropdown}},[_c('div',{staticClass:"label-wrap",class:{ 'without-label': !_vm.label }},[(_vm.label)?_c('span',{staticClass:"dropdown-label"},[_vm._v(" "+_vm._s(_vm.label))]):_vm._e(),_c('span',{staticClass:"dropdown-selected"},[_vm._v(" "+_vm._s(_vm.emptyListText || _vm.translate('emptyList'))+" ")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }