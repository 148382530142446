<template>
  <Portal to="root-widget">
    <ConfirmationModal
      v-if="confirmDialog.isRevealed.value"
      class="custom-popup-wrap"
      :title="deleteItemLabel"
      :width-class="'w-[354px]'"
      :confirm-button-text="deleteItemLabel"
      :confirm-button-class="confirmButtonClasses"
      :confirm-button-variation="'large-fixed'"
      :confirm-button-type="'secondary'"
      :cancel-button-type="'secondary'"
      :button-wrap-class="'flex mt-6'"
      @confirm="handleDelete"
      @cancel="closePopup"
    >
      <template #content>
        <input
          :ref="'hiddenInput'"
          v-autofocus
          readonly="readonly"
          class="hidden-input"
          data-hidden-input="true"
        />
        <div class="text-sm font-sans">
          <span class="text-text-default whitespace-normal break-words overflow-visible">
            {{ popupText }}
          </span>
        </div>
        <div
          v-if="isInputRequired"
          class="pt-2 text-sm text-text-default font-sans"
        >
          <span class="mt-2">{{
            translate('ifYouWantToDeleteResource', { resourceType: translate(type).toLowerCase() })
          }}</span>
          <span class="flex flex-wrap mt-[24px]">
            {{ resourceNameLabel }}:&nbsp;
            <span
              @click.stop
              class="font-medium select-text"
            >
              {{ resourceTitle }}
            </span>
          </span>
          <StInput
            ref="deleteCheckInput"
            v-model="titleInput"
            :text-value="titleInput"
            :input-height="36"
            :label-text="''"
            :input-type="'text'"
            :placeholder-text="translate('itemName')"
            class="w-[320px]"
            @click.native.stop
            @enter-key="handleDelete"
          />
        </div>
      </template>
    </ConfirmationModal>
  </Portal>
</template>

<script setup lang="ts">
import StInput from '@/components/elements/StInput';
import { ResourceType } from '@/types/resources';
import { getCurrentInstance, computed, ref, onMounted, onUnmounted } from 'vue';
import useTranslate from '@/utilities/composables/useTranslate';
import useDeleteResource from '@/utilities/composables/useDeleteResource';
import { Portal } from 'portal-vue';
import { useConfirmDialog } from '@vueuse/core';
import ConfirmationModal from '@/components/widgets/ConfirmationModal.vue';

const confirmDialog = useConfirmDialog();
const { translate } = useTranslate();
const _this = getCurrentInstance()!.proxy as any;

const titleInput = ref('');
const type = ref('');
const id = ref('');

const { deleteResource, resourceTitle } = useDeleteResource(id, type);

onMounted(() => {
  [type.value, id.value] = _this.$store.getters.getDeletePopupId.split('-');
  window.addEventListener('keydown', keyHandler);

  focusHiddenInput();
  confirmDialog.reveal();
});

onUnmounted(() => {
  window.removeEventListener('keydown', keyHandler);
});

const keyHandler = ($event: KeyboardEvent) => {
  $event.stopPropagation();

  if ($event.key === 'Escape') {
    closePopup();
  } else if ($event.key === 'Enter') {
    handleDelete();
  }
};

// labels and input

const popupText = computed((): string => {
  const key = ResourceType.Epic === type.value ? 'epicWillBeDeleted' : 'resourceWillBeDeleted';
  return [ResourceType.View, ResourceType.Epic, ResourceType.Card].includes(type.value)
    ? translate(key, {
        resourceType: translate(type.value).toLowerCase(),
        resourceTitle: translate(resourceTitle.value),
      })
    : translate('resourceAndContentWillBeDeleted', {
        resourceType: translate(type.value).toLowerCase(),
        resourceTitle: translate(resourceTitle.value),
      });
});

const resourceNameLabel = computed((): string => {
  switch (type.value) {
    case ResourceType.Project:
      return translate('spaceNameLabel');
    case ResourceType.Board:
      return translate('boardName');
    case ResourceType.List:
      return translate('listNameLabel');
    default:
      return translate('name');
  }
});

const deleteItemLabel = computed((): string => {
  return translate('deleteItem', { item: translate(type.value).toLowerCase() });
});

const isInputValid = computed((): boolean => {
  return (
    !!titleInput.value &&
    !!resourceTitle.value &&
    titleInput.value.toLowerCase() == resourceTitle.value.trim().replace(/\s+/g, ' ').toLowerCase()
  );
});

const isInputRequired = computed((): boolean => {
  return [ResourceType.Project, ResourceType.Board, ResourceType.List].includes(type.value);
});

// delete function

const handleDelete = async () => {
  if (isInputRequired.value && !isInputValid.value) return;

  closePopup();
  try {
    await deleteResource();
    displayNotification(true);
  } catch {
    displayNotification(false);
  }
};

// helpers

const focusHiddenInput = () => {
  _this.$nextTick(() => _this.$refs.hiddenInput?.focus());
};

const closePopup = () => {
  _this.$store.dispatch('deletePopupId', '');
};

const displayNotification = (success = true) => {
  const interpolationData = {
    name: resourceTitle.value,
    type: translate(type.value).toLowerCase(),
  };
  const message = translate(success ? 'deleteSuccess' : 'deleteError', interpolationData);

  _this.$store.dispatch('addUiNotification', {
    message,
    icon: 'info',
    duration: 4000,
  });
};

// buttons

const confirmButtonClasses = computed(() => {
  return `${!isInputRequired.value || isInputValid.value ? 'red-active' : ''}`;
});
</script>

<style lang="scss">
@import '@/assets/scss/customPopup';
</style>
