<template functional>
  <div
    class="shortcut-key-pill drag-scroll-disable usn border"
    :class="data.staticClass"
    :style="{
      backgroundColor: props.backgroundColor,
      minWidth: `${props.size}px`,
      height: `${props.size}px`,
      fontSize: `${props.fontSize}px`,
      borderRadius: `${props.borderRadius}px`,
      color: props.fontColor,
      border: `1px solid ${props.borderColor}`,
    }"
  >
    <span class="drag-scroll-disable">{{ props.text }}</span>
  </div>
</template>

<script>
import styleVariables from '@/assets/scss/variables.module.scss';

export default {
  name: 'ShortcutKeyPill',

  props: {
    text: {
      type: String,
      required: true,
    },
    backgroundColor: {
      type: String,
      required: false,
      default: styleVariables.gray100,
    },
    borderRadius: {
      type: Number,
      required: false,
      default: 4,
    },
    size: {
      type: Number,
      required: false,
      default: 20,
    },
    fontSize: {
      type: Number,
      required: false,
      default: 14,
    },
    fontColor: {
      type: String,
      required: false,
      default: styleVariables.gray400,
    },
    borderColor: {
      type: String,
      required: false,
      default: styleVariables.gray100,
    },
  },
};
</script>

<style lang="scss">
.shortcut-key-pill {
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    padding: 0 5px;
  }
}
</style>
