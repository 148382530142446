<template>
  <div
    class="blur-wrap"
    :style="{ height: `${respH}px`, width: `${respW}px` }"
  >
    <div
      ref="placeholder"
      :style="placeholderStyle"
      :class="{ hide: showBlur }"
      class="grey-background"
    />
    <canvas
      v-show="showBlur"
      ref="canvas"
      :style="blurhashCanvas"
      :width="respW"
      :height="respH"
    />
  </div>
</template>

<script>
import EventBus from '@/utilities/eventBus';

export default {
  name: 'BlurHashImage',

  props: {
    hash: {
      type: String,
      required: true,
    },
    h: {
      type: Number,
      required: false,
      default: 88,
    },
    w: {
      type: Number,
      required: false,
      default: 157,
    },
    borderRadius: {
      type: String,
      required: false,
      default: '8px',
    },
  },

  data() {
    return {
      mount: true,
      showBlur: false,
      blurTimeout: null,
      placeholderStyle: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        opacity: '1',
        transition: 'opacity 1000ms ease-in',
        background: 'linear-gradient(123.31deg, #DADCDF -0.03%, rgba(255, 255, 255, 0) 115.1%)',
        borderRadius: this.borderRadius,
        overflow: 'hidden',
      },
      blurhashCanvas: {
        opacity: '0',
        transition: 'opacity 1000ms ease-in-out',
      },
    };
  },

  computed: {
    respW() {
      return Math.round(this.w);
    },
    respH() {
      return Math.round(this.h);
    },
  },

  watch: {
    hash(val) {
      this.watcherCheck(val);
    },

    h(val) {
      this.watcherCheck(val);
    },

    w(val) {
      this.watcherCheck(val);
    },
  },

  mounted() {
    EventBus.$on(this.hash, this.handleWorkerMessage);

    if (process.env.VUE_APP_ID !== 'public') {
      this.$blurhashWorker.postMessage({
        hash: this.hash,
        height: this.respH,
        width: this.respW,
      });
    }
  },

  destroyed() {
    clearTimeout(this.blurTimeout);
    this.blurTimeout = null;
    EventBus.$off(this.hash, this.handleWorkerMessage);
  },

  methods: {
    paintImage(imageData) {
      if (!imageData) return;
      const context = this.$refs.canvas.getContext('2d');
      context.putImageData(imageData, 0, 0);
    },

    handleWorkerMessage({ imageData, width, height }) {
      if (this.respW !== width || this.respH !== height) {
        return;
      }

      this.showBlur = true;
      if (this.$refs.placeholder) {
        this.$refs.placeholder.style.opacity = '0';
      }
      if (this.$refs.canvas) {
        this.$refs.canvas.style.opacity = '1';
      }
      this.paintImage(imageData);
    },

    watcherCheck(val) {
      if (val) {
        this.paintImage();
      }
    },
  },
};
</script>

<style lang="scss">
.blur-wrap {
  &.page-icon {
    canvas {
      @apply rounded-lg;
    }
  }
}
</style>
