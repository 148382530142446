var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{ref:"button",class:[
    'button-wrap usn',
    _vm.type,
    _vm.variation,
    _vm.disabled ? 'disabled pen' : '',
    { 'right-spacing': _vm.rightSpacing },
    { 'left-spacing': _vm.leftSpacing },
  ],style:(_vm.buttonDimensionsStyle),on:{"mousedown":_vm.mousedownHandler,"click":_vm.clickHandler}},[_vm._t("default",function(){return [(_vm.labelLeft && !_vm.noLabel)?_c('span',{staticClass:"button-label",class:{ 'animated-dots': _vm.animateThreeDot }},[_vm._v(" "+_vm._s(_vm.text)+" ")]):_vm._e(),(_vm.icon && !_vm.customIconName)?_c('em',{staticClass:"material-icons button-icon",class:{ 'text-icon-default group-hover:text-icon-hover': _vm.themeStyle }},[_vm._v(" "+_vm._s(_vm.icon)+" ")]):_vm._e(),(_vm.customIconName)?_c('em',{staticClass:"button-icon custom-icons custom",class:{ 'bg-icon-default group-hover:bg-icon-hover': _vm.themeStyle },style:(_vm.customIconStyle)}):_vm._e(),(!_vm.labelLeft && !_vm.noLabel)?_c('span',{staticClass:"button-label",class:{ 'animated-dots': _vm.animateThreeDot }},[_vm._v(" "+_vm._s(_vm.text)+" ")]):_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }