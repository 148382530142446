<template>
  <header>
    <slot name="title">
      <h1
        v-if="props.title"
        :class="titleClass"
      >
        {{ typeof props.title === 'string' ? props.title : props.title.value }}
      </h1>
    </slot>
    <slot name="subtitle">
      <h2
        v-if="props.subtitle"
        :class="subtitleClass"
      >
        {{ typeof props.subtitle === 'string' ? props.subtitle : props.subtitle.value }}
      </h2>
    </slot>
  </header>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    title?:
      | {
          value: string;
          class: string;
        }
      | string;
    subtitle?:
      | {
          value: string;
          class: string;
        }
      | string;
  }>(),
  {
    title: '',
    subtitle: '',
  }
);

const titleClass = computed(() => (typeof props.title === 'string' ? '' : props.title.class));
const subtitleClass = computed(() =>
  typeof props.subtitle === 'string' ? '' : props.subtitle.class
);
</script>

<style scoped lang="scss">
header {
  @apply text-text-default;
  h1 {
    @apply font-medium
      text-2xl
      mb-1
      text-text-default;
  }
  h2 {
    @apply text-sm
      text-text-default;
    font-weight: 400;
  }
}
</style>
