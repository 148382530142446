<template>
  <div
    class="public-page-item-section"
    :class="{ highlighted }"
    @click.stop
  >
    <div class="public-page-item-text usn">
      {{ translate('publishesSubpagesButNoComments') }}
    </div>
    <div
      v-if="url"
      class="public-page-item-text public-page-link"
    >
      <span class="url-text">{{ url }}</span>
      <div
        class="copy-link-button pt"
        @click.stop="copyUrl"
        @touchstart.stop
      >
        <em
          :style="getCustomIconStyle(iconPath('link-st'))"
          class="custom-icons copy-public-link-icon"
        />
      </div>
    </div>
  </div>
</template>

<script>
import customIconsMixin from '@/mixins/customIconsMixin';

export default {
  name: 'PublicPageListItem',

  mixins: [customIconsMixin],

  props: {
    url: {
      type: String,
      required: true,
    },
    highlighted: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    copyUrl() {
      navigator.clipboard.writeText(this.url);

      this.$store.dispatch('addUiNotification', {
        message: this.translate('linkCopiedToClipboard'),
        icon: 'link-st',
        duration: 4000,
      });
    },
  },
};
</script>

<style lang="scss">
.public-page-item-section {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding: 0px 16px 8px;

  &.highlighted {
    @apply bg-important-hover;
  }

  .public-page-item-text {
    font-size: 12px;
    line-height: 18px;
    @apply text-text-default;

    &.public-page-link {
      height: 32px;
      display: flex;
      align-items: center;
      border: 1px solid;
      box-sizing: border-box;
      border-radius: 8px;
      overflow: hidden;
      @apply border-important-border bg-important-bg text-text-default;

      .copy-link-button {
        display: flex;
        justify-content: center;
        align-items: center;
        border-left: 1px solid;
        padding: 7px;
        @apply border-dividers-default;

        &:hover {
          @apply bg-important-hover;

          .copy-public-link-icon {
            @apply bg-icon-default;
          }
        }
      }

      .url-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 24px;
        padding: 0px 8px 0px 13px;
      }
    }
  }
}
</style>
