<template>
  <span
    v-if="memberRole === 'guest'"
    :class="classStyle"
  >
    ({{ translate('guest') }})</span
  >
</template>

<script>
export default {
  name: 'GuestMemberLabel',
  props: {
    memberRole: {
      type: String,
      required: true,
    },
    classStyle: {
      type: String,
      default: '',
    },
  },
};
</script>
