<template>
  <div
    class="project-icon-small-wrap"
    @click="$emit('click')"
  >
    <EmojiIcon
      v-if="isEmoji"
      :emoji="projectIcon"
      :classes="'flex justify-center items-center'"
    />
    <em
      v-if="isIcon"
      class="custom-icons flex"
      :style="[getCustomIconStyle(iconPath(projectIcon)), { background: projectColor }]"
    />
  </div>
</template>

<script>
import customIconsMixin from '@/mixins/customIconsMixin';
import EmojiIcon from '@/components/functional/EmojiIcon.vue';

export default {
  name: 'ProjectIcon',

  components: { EmojiIcon },

  mixins: [customIconsMixin],

  props: {
    projectIcon: {
      type: String,
      required: true,
    },
    projectColor: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: 'icon',
    },
  },

  computed: {
    isEmoji() {
      return this.type === 'emoji';
    },
    isIcon() {
      return this.type === 'icon';
    },
  },
};
</script>
