<template functional>
  <div
    :key="props.memberId"
    class="avatar"
    :class="[
      { initials: !props.thumbnailImage && !props.hiddenMember },
      props.size,
      { 'border-important-border border-solid border': props.hiddenMember },
      { inactive: props.inactive },
    ]"
    :style="props.fallbackStyle"
  >
    <div
      v-if="!props.thumbnailImage && !props.hiddenMember"
      class="avatar-initials"
    >
      {{ props.memberInitials }}
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'UserAvatarContent',
  props: {
    thumbnailImage: {
      type: String,
      required: true,
    },
    memberInitials: {
      type: String,
      required: true,
    },
    memberId: {
      type: String,
      required: true,
    },
    fallbackStyle: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      required: false,
      default: 'small',
    },
    inactive: {
      type: Boolean,
      required: false,
      default: false,
    },
    hiddenMember: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
