<template>
  <img
    v-if="type === imageEnum && src"
    :src="generateUrl(src)"
    alt="page icon"
    :width="$cons.resourceIconDefault.width"
    :height="$cons.resourceIconDefault.height"
    class="object-cover resource-icon"
    :class="imgClass"
    :style="imgStyle"
  />
  <span
    v-else-if="type === emojiEnum && src"
    :class="emojiClass"
    class="emoji-icon resource-icon"
    :style="{
      fontSize: '14px',
      width: '16px',
      height: '16px',
      textAlign: 'center',
      display: 'inline-flex',
      ...emojiStyle,
    }"
    >{{ src }}</span
  >
  <em
    v-else
    class="custom-icons resource-icon"
    :class="iconClass"
    :style="[
      getCustomIconStyle(iconPath(src)),
      { background: color || 'var(--iconDefault)' },
      iconStyle,
    ]"
    >&nbsp;</em
  >
</template>

<script setup lang="ts">
import generateSrcUrl from '@/utilities/generateSrcUrl';
import { getCurrentInstance } from 'vue';
import useCustomIcon from '@/utilities/composables/useCustomIcon';

const { iconPath, getCustomIconStyle } = useCustomIcon();

const _this = getCurrentInstance()!.proxy as any;

const props = withDefaults(
  defineProps<{
    src?: string;
    color?: string;
    type?: string;
    imgClass?: string;
    imgStyle?: Record<string, string>;
    emojiClass?: string;
    emojiStyle?: Record<string, string>;
    iconClass?: string;
    iconStyle?: Record<string, string>;
  }>(),
  {
    src: '',
    color: '',
    type: '',
    imgClass: '',
    imgStyle: () => ({}),
    emojiClass: '',
    emojiStyle: () => ({}),
    iconClass: '',
    iconStyle: () => ({}),
  }
);

const generateUrl = (src: string): string => {
  return generateSrcUrl(
    src,
    _this.$cons.resourceIconDefault.width,
    _this.$cons.resourceIconDefault.height,
    true
  );
};
</script>

<style lang="scss">
.resource-icon {
  @apply inline-flex justify-center items-center w-4 h-4 min-w-4;
}
</style>
