var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"onboarding-screen",style:({
    backgroundImage: 'url(' + _vm.plateImage('png', 'background') + ')',
  })},[(_vm.displayFirstScreen)?_c('div',{staticClass:"first-last-screen"},[_c('div',{staticClass:"text-text-default bold text-2xl text-center mb-4"},[_vm._v(" "+_vm._s(_vm.translate('welcomeToSuperThread'))+" ")]),_c('div',{staticClass:"mb-[60px] flex items-center flex-wrap justify-center"},[_c('span',{staticClass:"text-text-default text-center"},[_vm._v(" "+_vm._s(_vm.translate('wedLikeToTake'))+" ")]),_c('span',{staticClass:"text-text-default text-center bold ml-2"},[_vm._v(" "+_vm._s(_vm.translate('thirtySeconds'))+" ")]),_c('img',{staticClass:"inline-block ml-2 mr-2",attrs:{"src":_vm.plateImage('svg', 'fast-clock'),"alt":"clock"}}),_c('span',{staticClass:"text-text-default text-center"},[_vm._v(" "+_vm._s(_vm.translate('toShowYouAround'))+" ")])]),_c('ButtonCustom',{staticClass:"first-last-screen-button",attrs:{"text":_vm.translate('getStarted'),"type":'popup-button-wrap attention',"button-dimensions":{
        width: 262,
        height: 48,
      }},on:{"click":_vm.continueOnboarding}})],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.displayLastScreen && !_vm.displayFirstScreen),expression:"!displayLastScreen && !displayFirstScreen"}],staticClass:"onboarding-content"},[_vm._l((_vm.lections),function(lection,index){return _c('div',{key:lection.id,staticClass:"onboarding-lectures"},[_c('div',{staticClass:"lecture-steps",class:{ 'hidden-image': _vm.selectedLectureIndex !== index }},_vm._l((lection.steps),function(step,stepIndex){return _c('div',{key:stepIndex,staticClass:"step-wrap"},[_c('div',{class:{ 'hidden-image': _vm.selectedStepIndex !== stepIndex }},[_c('div',{staticClass:"image-container"},[(step.imgBase)?_c('picture',{staticClass:"step-image"},[_c('source',{attrs:{"srcset":_vm.plateImage('avif', step.imgBase),"type":"image/avif"}}),_c('source',{attrs:{"srcset":_vm.plateImage('webp', step.imgBase),"type":"image/webp"}}),_c('img',{staticClass:"step-image",attrs:{"src":_vm.plateImage('png', step.imgBase),"alt":"no pages"}})]):_vm._e()])])])}),0)])}),_c('ButtonCustom',{staticClass:"continue-button",class:{
        show: !_vm.hideContinueButton,
      },attrs:{"text":_vm.translate('continue'),"type":'popup-button-wrap attention',"button-dimensions":{
        width: 131,
        height: 36,
      }},on:{"click":_vm.nextLecture}}),_c('div',{staticClass:"onboarding-footer",class:{
        show: !_vm.hideContinueButton,
      }},[_c('div',{staticClass:"skip-button m-0"}),_c('div',{staticClass:"step-buttons"},_vm._l((_vm.lections),function(lection,index){return _c('div',{key:lection.id,staticClass:"step-button",class:_vm.selectedLectureIndex === index ? 'bg-attention-bg w-6' : ''})}),0),_c('div',{staticClass:"skip-button pt",on:{"click":_vm.goToLastScreen}},[_vm._v(" "+_vm._s(_vm.translate('skipToEnd'))+" "),_c('em',{staticClass:"custom-icon flex h-4 w-4 bg-icon-default",style:(_vm.getCustomIconStyle(_vm.iconPath('arrow-right')))})])])],2),(_vm.displayLastScreen)?_c('div',{staticClass:"first-last-screen"},[_c('div',{staticClass:"text-text-default bold text-2xl text-center mb-4"},[_vm._v(" "+_vm._s(_vm.translate('youreGoodToGo'))+" ")]),_c('div',{staticClass:"mb-[60px] text-center"},[_c('span',{staticClass:"text-text-default text-center"},[_vm._v(" "+_vm._s(_vm.translate('youCanAlways'))+" ")])]),_c('ButtonCustom',{staticClass:"first-last-screen-button",attrs:{"text":_vm.translate('openSuperthread'),"type":'popup-button-wrap attention',"button-dimensions":{
        width: 262,
        height: 48,
      }},on:{"click":_vm.closeOnboarding}})],1):_vm._e(),_c('div',{staticClass:"onboarding-logo-header"},[_c('img',{attrs:{"width":"34","height":"34","src":_vm.logoImg('onboarding-logo'),"alt":"superthread logo"}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }