var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.type === _vm.imageEnum && _vm.src)?_c('img',{staticClass:"object-cover resource-icon",class:_vm.imgClass,style:(_vm.imgStyle),attrs:{"src":_setup.generateUrl(_vm.src),"alt":"page icon","width":_vm.$cons.resourceIconDefault.width,"height":_vm.$cons.resourceIconDefault.height}}):(_vm.type === _vm.emojiEnum && _vm.src)?_c('span',{staticClass:"emoji-icon resource-icon",class:_vm.emojiClass,style:({
    fontSize: '14px',
    width: '16px',
    height: '16px',
    textAlign: 'center',
    display: 'inline-flex',
    ..._vm.emojiStyle,
  })},[_vm._v(_vm._s(_vm.src))]):_c('em',{staticClass:"custom-icons resource-icon",class:_vm.iconClass,style:([
    _setup.getCustomIconStyle(_setup.iconPath(_vm.src)),
    { background: _vm.color || 'var(--iconDefault)' },
    _vm.iconStyle,
  ])},[_vm._v(" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }