var render = function render(_c,_vm){return _c('span',{staticClass:"emoji-icon",class:_vm.props.classes,style:({
    fontSize: '14px',
    width: '16px',
    height: '16px',
    textAlign: 'center',
    ..._vm.props.customStyle,
  })},[_vm._v(_vm._s(_vm.props.emoji))])
}
var staticRenderFns = []

export { render, staticRenderFns }