<template>
  <div
    class="checkbox relative checkbox--${mod}"
    :class="[
      { 'checkbox--sideSpacesPrimary': sideSpacesPrimary },
      { 'checkbox--checkboxRight': mod === ISthCheckboxMod.checkboxRight },
    ]"
  >
    <input
      :id="generatedId"
      :value="value"
      :checked="checked"
      class="checkbox__control box-border absolute opacity-0 cursor-pointer p-0 m-0"
      type="checkbox"
      @change="toggleValue"
    />
    <label
      class="checkbox__label text-gray-700 cursor-pointer flex items-center grow"
      :for="generatedId"
      @touchstart="handleClickOutsideCheckbox"
      @click="handleClickOutsideCheckbox"
    >
      <em
        class="checkbox__labelIcon material-icons absolute block transition-colors box-border overflow-hidden"
        @touchstart="handleClickOutsideCheckbox"
        @click="handleClickOnCheckbox"
      >
        {{ checked ? IMaterialICons.check : '' }}
      </em>
      <span class="checkbox__labelText flex items-center usn">
        <em
          v-if="customIconName"
          :style="[getCustomIconStyle(customIconName), { background: customIconColor }]"
          class="custom-icons checkbox__labelTextIcon block"
        />
        {{ label }}
      </span>
    </label>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { PropType } from 'vue/types/v3-component-props';
import { IMaterialICons } from '@/assets/material-icons';
import { nanoid } from 'nanoid';
import customIconsMixin from '@/mixins/customIconsMixin';
export interface ISthCheckboxValue {
  value: boolean;
  clickedOutsideCheckbox: boolean;
}
interface ISthDataModel {
  checked: boolean;
  generatedId: string;
  clickedOutsideCheckbox: boolean;
}
enum ISthCheckboxMod {
  checkboxRight = 'checkboxRight',
}
export default defineComponent({
  name: 'SthCheckbox',
  mixins: [customIconsMixin],
  enums: {
    ISthCheckboxMod,
    IMaterialICons,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    mod: {
      type: String as PropType<ISthCheckboxMod>,
      required: false,
      default: ISthCheckboxMod.checkboxRight,
    },
    sideSpacesPrimary: {
      type: Boolean,
      required: false,
      default: false,
    },
    customIconName: {
      type: String,
      required: false,
      default: '',
    },
    customIconColor: {
      type: String,
      required: false,
      default: '',
    },
  },
  data(): ISthDataModel {
    return {
      checked: this.value,
      generatedId: nanoid(8),
      clickedOutsideCheckbox: false,
    };
  },
  watch: {
    value(newValue: boolean): void {
      this.checked = newValue;
    },
  },
  methods: {
    toggleValue(event: Event): void {
      event.stopPropagation();
      this.checked = !this.checked;
      this.$emit('changeValue', {
        value: this.checked,
        clickedOutsideCheckbox: this.clickedOutsideCheckbox,
      });
    },
    handleClickOutsideCheckbox(mouseEvent: MouseEvent): void {
      mouseEvent.stopPropagation();
      this.clickedOutsideCheckbox = true;
    },
    handleClickOnCheckbox(mouseEvent: MouseEvent): void {
      mouseEvent.stopPropagation();
      this.clickedOutsideCheckbox = false;
    },
  },
});
</script>

<style lang="scss">
@import '../../assets/scss/typography';
@import '../../assets/scss/mixins/index';
$hCheckboxItem: 40px;
$szCheckbox: 16px;
.checkbox {
  z-index: 1;
  padding-right: 0;
  &--checkboxRight {
    .checkbox {
      &__labelIcon,
      &__control {
        right: 0;
      }
    }
  }
  &--sideSpacesPrimary {
    .checkbox {
      &__label {
        @include padding(null $spaceRightPrimary null $spaceLeftPrimary);
      }
      &__labelIcon,
      &__control {
        right: $spaceRightPrimary;
        @apply bg-important-bg;
      }
    }
  }
  &__control {
    @include size($szCheckbox, $szCheckbox);
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    &:checked + .checkbox__label .checkbox__labelIcon {
      color: $stBlue;
      border-color: $stBlue;
      @apply bg-important-bg;
    }
  }
  &__label {
    height: $hCheckboxItem;
  }
  &__labelText {
    @apply text-text-default;
    @include textSmNormal();
  }
  &__labelTextIcon {
    margin-right: $spaceRightMd;
  }
  &__labelIcon {
    font-size: $szCheckbox - 2px !important;
    min-width: $szCheckbox;
    min-height: $szCheckbox;
    max-width: $szCheckbox;
    max-height: $szCheckbox;
    color: $gray300;
    transition: $transitionPrimary;
    border: 1px solid $gray300;
    border-radius: $spaceXs;
    background-color: $stPrimaryBackgroundColor;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
