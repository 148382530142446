var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"electron-extras py-1 flex justify-end items-center",class:{ 'electron-drag': (!_vm.isAnyCardDisplayed && !_vm.showPeekSidebar) || _vm.isSettings }},[_c('div',{staticClass:"flex just items-center h-8 mr-2 ml-2 gap-2 electron-no-drag",class:_vm.isWindowsOS || (!_vm.isSettings && _vm.showPeekSidebar) ? 'justify-start w-full' : 'justify-end'},[_vm._l((['left', 'right']),function(direction,index){return _c('div',{key:index,staticClass:"group flex items-center justify-center p-0.5 cursor-pointer h-6 w-6 rounded",class:{
        'pointer-events-none': _vm.isDisabled(direction),
        'cursor-pointer hover:bg-hover-onHover':
          !_vm.isDisabled(direction) && !_vm.isAnyCardDisplayedInModalLayout,
        'cursor-pointer hover:bg-hover-electron':
          _vm.isAnyCardDisplayedInModalLayout && !_vm.isDisabled(direction),
      },on:{"click":function($event){return _vm.throttledNavigateButtonClick(direction)}}},[_c('em',{staticClass:"custom-icons bg-icon-default",class:{
          'bg-inactive-icon': _vm.isDisabled(direction),
          'group-hover:bg-icon-hover': !_vm.isDisabled(direction) && !_vm.isAnyCardDisplayedInModalLayout,
          'bg-icon-electron': _vm.isAnyCardDisplayedInModalLayout && !_vm.isDisabled(direction),
        },style:(_vm.getCustomIconStyle(_vm.iconPath(`chevron-${direction}`)))})])}),_c('v-popover',{staticClass:"flex",class:{ 'ml-auto': _vm.showPeekSidebar || _vm.isWindowsOS },attrs:{"open":_vm.recentlyViewedDropdownShown,"trigger":'click',"placement":"bottom-end"}},[_c('div',{staticClass:"group flex items-center justify-center cursor-pointer p-0.5 h-6 w-6 rounded",class:{
          'hover:bg-hover-onHover': !_vm.isAnyCardDisplayedInModalLayout,
          'hover:bg-hover-electron': _vm.isAnyCardDisplayedInModalLayout,
        },on:{"click":_vm.toggleRecentlyViewedDropdown}},[_c('em',{staticClass:"custom-icons bg-icon-default",class:{
            'group-hover:bg-icon-hover': !_vm.isAnyCardDisplayedInModalLayout,
            'bg-icon-electron': _vm.isAnyCardDisplayedInModalLayout,
          },style:(_vm.getCustomIconStyle(_vm.iconPath('clock')))})]),_c('template',{slot:"popover"},[(_vm.recentlyViewedDropdownShown)?_c('ListDropdown',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideRecentlyViewedDropdown),expression:"hideRecentlyViewedDropdown"}],staticClass:"relative -mr-2",attrs:{"width":240,"items":_vm.recentlyViewedDropdownItems,"title":_vm.translate('electronRecentlyViewed')},on:{"click":_vm.handleRecentlyViewedDropdownItemClick,"closeEscape":_vm.hideRecentlyViewedDropdown,"hoverDropdownItem":_vm.handleRecentlyViewedDropdownItemHover}}):_vm._e()],1)],2)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }