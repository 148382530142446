import { render, staticRenderFns } from "./SthCheckbox.vue?vue&type=template&id=4954df1a"
import script from "./SthCheckbox.vue?vue&type=script&lang=ts"
export * from "./SthCheckbox.vue?vue&type=script&lang=ts"
import style0 from "./SthCheckbox.vue?vue&type=style&index=0&id=4954df1a&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports