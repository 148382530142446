var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.showGroupingLabel && !_setup.hideProjectGroups)?_c('div',{staticClass:"filter-dropdown-label usn"},[(_vm.item.groupIconType === _vm.emojiEnum)?_c(_setup.EmojiIcon,{attrs:{"emoji":_vm.item.groupIcon,"classes":'flex justify-center items-center mr-3',"custom-style":{ fontSize: '12px' }}}):_c('em',{staticClass:"custom-icons",class:{
        'mr-3': _vm.item.groupIcon,
      },style:([_vm.getCustomIconStyle(_vm.iconPath(_vm.item.groupIcon)), { background: _vm.item.groupIconColor }])}),_c('span',[_vm._v(_vm._s(_vm.item.groupLabel))]),(_vm.item.groupPrivateItem)?_c('em',{staticClass:"custom-icons private-icon",style:([_vm.getCustomIconStyle(_vm.iconPath('private-project'))])}):_vm._e()],1):_vm._e(),_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({
      text: _vm.item.tooltipText,
      tippyOptions: { placement: 'bottom' },
      disabled: !_vm.item.showTooltip,
    }),expression:"{\n      text: item.tooltipText,\n      tippyOptions: { placement: 'bottom' },\n      disabled: !item.showTooltip,\n    }"}],ref:"item",staticClass:"filter-dropdown-item pt usn",class:{
      'high-lighted': _vm.highlighted,
      disabled: _vm.isDisabled,
      separator: _vm.item.separator,
      lighter: _vm.item.lighter,
      ctg: _vm.item.category,
      stg: _vm.item.strong,
      'pl-4': _setup.hideProjectGroups,
    },on:{"click":_vm.itemClick,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.itemClick.apply(null, arguments)},"mouseenter":_vm.itemMouseEnter,"mouseleave":_vm.itemMouseLeave}},[(_vm.item.checkbox)?_c('em',{staticClass:"checkbox-icon material-icons",class:[{ checked: _vm.checkedItem, disabled: _vm.isDisabled }]},[_vm._v(" "+_vm._s(_vm.checkedItem ? 'check' : '')+" ")]):_vm._e(),_c('div',{staticClass:"filter-item-icon-label",on:{"click":_vm.itemRightSideClick}},[(_vm.item.isInvitee && _vm.item.customIcon && !_vm.item.avatar)?_c(_setup.GenericUserAvatarIcon):(_vm.resultType(_vm.item) !== _vm.emojiEnum && _vm.item.customIcon && !_vm.item.avatar)?_c('em',{staticClass:"custom-icons board-icon flex-shrink-0",class:{
          'bg-icon-default': _vm.item.icon === 'avatar',
        },style:(_vm.iconStyle)}):_vm._e(),(_vm.resultType(_vm.item) === _vm.emojiEnum)?_c(_setup.EmojiIcon,{attrs:{"emoji":_vm.item.icon,"classes":'mr-2'}}):_vm._e(),(!_vm.item.customIcon && _vm.item.icon)?_c('em',{staticClass:"material-icons"},[_vm._v(_vm._s(_vm.item.icon))]):_vm._e(),(_vm.item.avatar)?_c(_setup.UserAvatar,{attrs:{"member":{ id: _vm.item.id }}}):_vm._e(),(_vm.item.breadcrumbLabel)?_c('span',{staticClass:"item-breadcrumb text-inactive-text font-medium"},[_vm._v(" "+_vm._s(_vm.item.breadcrumbLabel)),_c('span',{staticClass:"mr-2 ml-2"},[_vm._v(">")])]):_vm._e(),_c('span',{staticClass:"item-label",on:{"mouseenter":function($event){_vm.displayMemberHoverPreview($event, _vm.getMemberHover(_vm.item.id))}}},[_vm._v(" "+_vm._s(_vm.item.label)+" ")]),(_vm.item.privateItem)?_c('em',{staticClass:"custom-icons private-icon",style:([_vm.getCustomIconStyle(_vm.iconPath('private-project'))])}):_vm._e(),(!_vm.deactivated && _vm.memberRole === _setup.MemberRole.Guest)?_c(_setup.GuestMemberLabel,{attrs:{"member-role":_vm.memberRole,"class-style":'text-sm lowercase ml-1 text-text-default'}}):_vm._e(),(_vm.deactivated)?_c('span',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(_vm.deactivated)+" ")]):_vm._e(),(_vm.showEditIcon)?_c('em',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.translate('editWorkspaceTagsInSettings'),
          trigger: 'hover',
          placement: 'bottom',
          boundariesElement: 'window',
          classes: _vm.notEditableTag ? 'black-tooltip mt-19' : 'hide-tooltip',
        }),expression:"{\n          content: translate('editWorkspaceTagsInSettings'),\n          trigger: 'hover',\n          placement: 'bottom',\n          boundariesElement: 'window',\n          classes: notEditableTag ? 'black-tooltip mt-19' : 'hide-tooltip',\n        }"}],staticClass:"custom-icons item-edit-icon ml-auto min-w-4 min-h-4",class:{
          'bg-inactive-icon': _vm.notEditableTag,
        },style:(_vm.getCustomIconStyle(_vm.iconPath('edit'))),on:{"click":function($event){$event.stopPropagation();return _vm.editItem.apply(null, arguments)}}}):_vm._e(),(_vm.showChechIcon)?_c('em',{staticClass:"custom-icons ml-auto mr-0 bg-blue-600 w-6 h-6",style:(_vm.getCustomIconStyle(_vm.iconPath('check')))}):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }